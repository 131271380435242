import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import { MdClear } from 'react-icons/md';

const styles = (theme) => ({
  close: {
    padding: theme.spacing(0.5),
  },
});

class Toast extends React.Component {
  state = {
    open: false,
    message: '',
  };

  componentDidMount() {
    window.addEventListener('show-toast', (event) => {
      this.setState({ message: event.detail.message });
      this.setState({ open: true });
    });
  }

  handleClick = () => {
    this.setState({ open: true });
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.open}
          autoHideDuration={6000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id='message-id'>{this.state.message}</span>}
          action={[
            <IconButton
              key='close'
              aria-label='Close'
              color='inherit'
              className={classes.close}
              onClick={this.handleClose}>
              <MdClear />
            </IconButton>,
          ]}
        />
      </div>
    );
  }
}

Toast.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Toast);
