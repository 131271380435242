import React, { Suspense } from 'react';
import AWS from 'aws-sdk';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import history from './components/utilities/History';
// import ConfirmClose from './ConfirmClose';
// Components
import { ApplicationSpinner, Loading } from '@zawarski/palmetto-ui-components';
//import "@zawarski/palmetto-ui-components/dist/styles/common.css";
//import "@zawarski/palmetto-ui-components/dist/styles/common.login.css";
import './styles/common.css';
import './styles/palmetto-tabs.css';

const HomeComponent = React.lazy(() => import('./components/app/VEOCHome'));
const DirectoryComponent = React.lazy(() => import('./components/app/Directory'));
const ChatHomeComponent = React.lazy(() => import('./components/app/CustomChatHome'));
const DirectoryViewComponent = React.lazy(() => import('./components/app/DirectoryView'));
const ManagePosition = React.lazy(() => import('./components/app/ManagePosition'));
const ManageStations = React.lazy(() => import('./components/app/ManageStations'));
const ChatComponent = React.lazy(() => import('./components/app/Chat.js'));
const ChatMembersComponent = React.lazy(() => import('./components/app/ChatMembers.js'));
const CustomChatEditpage = React.lazy(() => import('./components/app/CustomChatEditpage'));
//const StationsPage = React.lazy(() => import('./components/app/Stations'));
AWS.config.update({
	accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID, // Generated on step 1
	secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY, // Generated on step 1
	region: 'us-east-1', // Must be the same as your bucket
	signatureVersion: 'v4',
});

function App() {
	const appLoading = useSelector((state) => state.rootReducer.appLoading);
	return (
		<>
			{/* <ConfirmClose/> */}
			{appLoading ? (
				<div className='layout vertical vertical-center full-height'>
					<ApplicationSpinner/>
					<div className='height-20'></div>
					<span style={{padding: '0 16px'}} className='text-center title-big opacity-54'>
            Loading the app
          </span>
				</div>
			) : (
				<div className='full-height'>
					<Router history={history}>
						{/* id can be extracted from props.match.params.id */}
						<Switch>
							<Route
								exact
								path='/'
								component={(props) => (
									<Suspense fallback={<Loading/>}>
										<HomeComponent {...props} />
									</Suspense>
								)}
							/>
							<Route
								exact
								path='/directory'
								component={(props) => (
									<Suspense fallback={<Loading/>}>
										<DirectoryComponent {...props} />
									</Suspense>
								)}
							/>
							<Route
								exact
								path='/chathome'
								component={(props) => (
									<Suspense fallback={<Loading/>}>
										<ChatHomeComponent {...props} />
									</Suspense>
								)}
							/>
							{ /*
							<Route
								exact
								path='/chathome/station'
								component={(props) => (
									<Suspense fallback={<Loading/>}>
										<StationsPage {...props} />
									</Suspense>
								)}
							/>
							*/
							}
							<Route
								exact
								path='/directory/:position'
								component={(props) => (
									<Suspense fallback={<Loading/>}>
										<DirectoryViewComponent {...props} />
									</Suspense>
								)}
							/>
							<Route
								exact
								path='/manage_position'
								component={(props) => (
									<Suspense fallback={<Loading/>}>
										<ManagePosition {...props} />
									</Suspense>
								)}
							/>
							<Route
								exact
								path='/manage_stations'
								component={(props) => (
									<Suspense fallback={<Loading/>}>
										<ManageStations {...props} />
									</Suspense>
								)}
							/>
							<Route
								exact
								path='/chat/:chat_room'
								component={(props) => (
									<Suspense fallback={<Loading/>}>
										<ChatMembersComponent {...props} />
									</Suspense>
								)}
							/>
							<Route
								exact
								path='/chat/:chat_room/chat'
								component={(props) => (
									<Suspense fallback={<Loading/>}>
										<ChatComponent {...props} />
									</Suspense>
								)}
							/>
							<Route
								exact
								path='/customchat/new'
								component={(props) => (
									<Suspense fallback={<Loading/>}>
										<CustomChatEditpage newEntry={true} {...props} />
									</Suspense>
								)}
							/>
							<Route
								exact
								path='/customchat/:chat_room'
								component={(props) => (
									<Suspense fallback={<Loading/>}>
										<ChatComponent customchat={true} {...props} />
									</Suspense>
								)}
							/>
							<Route
								exact
								path='/customchat/:chat_room/edit'
								component={(props) => (
									<Suspense fallback={<Loading/>}>
										<CustomChatEditpage {...props} />
									</Suspense>
								)}
							/>
						</Switch>
					</Router>
				</div>
			)}
		</>
	);
}

export default App;
