/**
 * This file contains most of the actions used around the application
 */

import {
  connect,
  setConnectionStateChangeCallback,
} from "../utils/palmettoWebsocket";
// import { apiClient } from "../../common/axios_instance";

/**
 * This sets the default info right after the user logs in
 * @param {Object} payload
 */
export function setDefaultInfo(payload) {
  return async function (dispatch, _getState) {
    dispatch({
      type: "SET_SELECTED_GROUP",
      payload: payload.selectedCounty.pvGroupID,
    });
    dispatch({
      type: "SET_SELECTED_GROUP_NAME",
      payload: payload.selectedCounty.pvGroupName,
    });
    dispatch({
      type: "SET_SELECTED_PARENT_GROUP",
      payload: payload.selectedCounty.pvParentGroupID,
    });

    // dispatch({ type: "SET_APP_PERMISSIONS", payload: payload.permissions });
    dispatch({ type: "SET_PERMISSION_TYPE", payload: payload.permissionType });

    dispatch({
      type: "PALMETTO_USER_ACCOUNT",
      payload: payload.palmettoUserAccount,
    });
    await connect(
      payload.palmettoUserAccount.id,
      payload.selectedCounty.pvGroupID,
      payload.palmettoUserAccount.pvAdministrator,
      payload.creds.id,
    );
    setConnectionStateChangeCallback((state) => {
      dispatch({ type: "SET_WEBSOCKET_STATE", payload: state });
    });
    dispatch({ type: "APPLICATION_LOADING_STATE", payload: false });
  };
}

// export function getPositionsForUser() {
//   return async function (dispatch, getState) {
//     let userData = JSON.parse(sessionStorage.getItem("userAuthCreds"));
//     let positions = await apiClient.get(
//       `${process.env.REACT_APP_PALMETTO_ENDPOINT}/api/accounts/${userData.userId}/account2positions`,
//     );
//     if (positions && positions.data) {
//       dispatch({ type: "SET_USER_POSITIONS", payload: positions.data });
//     } else {
//       dispatch({ type: "SET_USER_POSITIONS", payload: [] });
//     }
//   };
// }
