/**
 * Contains all possible types for actions. This is also provides a single source of truth for all possible actions
 */

// Maps Action names to the variables that they change for the root reducer
export const ALL_TYPES = {
  ALL_PERSONNEL_DATASET: 'allPersonnelDataset', // shows a list of all personnel across all positions
  APPLICATION_LOADING_STATE: 'appLoading', // App Loading State
  CHAT_HISTORY: 'chatHistory', // History being displayed in the chat
  CUSTOM_CHAT_CURRENT_ENTRY: 'customChatCurrentEntry', // Custom Chat Current Entry
  HAS_NEW_CHAT: 'hasNewChat', // True if the bubble should be shown
  LAYOUT_CURRENT_ENTRY: 'layoutCurrentEntry', // Currently Selected Table
  LAYOUT_DATASET: 'layoutDataset', // Dataset of tables to be shown on the Home Page
  PALMETTO_USER_ACCOUNT: 'palmettoUserAccount', // basic user information from palmetto
  PERSONNEL_CURRENT_ENTRY: 'personnelCurrentEntry', // Personnel Current Entry
  PERSONNEL_DATASET: 'personnelDataset', // Dataset for Personnel in a single position
  POSITIONS_CURRENT_ENTRY: 'positionsCurrentEntry',
  POSITIONS_DATASET: 'positionsDataset', // Position Dataset that is displayed in the Directory page
  SELECTED_TAB: 'selectedTab', // Set the selected tab to show
  CURRENT_PATH: 'currentPath',
  SET_APP_PERMISSIONS: 'appPermissions', // Sets the app permissions
  SET_APP_TYPE: 'appType', // Sets the App Type
  SET_HEALTH: 'health', // true if the connection is still active and healthy
  SET_LOGIN_STATE: 'loginState', // true if user is logged in
  SET_SELECTED_GROUP: 'selectedGroup', // User Selected Group ID
  SET_SELECTED_GROUP_NAME: 'selectedGroupName', // User Selected Group Name
  SET_SELECTED_PARENT_GROUP: 'selectedParentGroup',
  SET_USER_POSITIONS: 'userPositions', // Sets the array of user positions
  SET_USER_SETTINGS: 'userSettings', // true if user is logged in
  SET_USER_TYPE: 'userType', // Defines if the user is a state or county user
  USERS_IN_GROUP: 'usersInGroup', // Sets the users in your group
  USER_ON_DUTY: 'userOnDuty', // True if the user is on duty
  SET_PERMISSION_TYPE: 'permissionType', // Sets the user's permission level
};
