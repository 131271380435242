import React from 'react';
import ReactDOM from 'react-dom/client';
import AppShell from './AppShell';
import store from './redux/store/store.js';
import { Provider } from 'react-redux';
import Toast from './components/utilities/Toast';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import AppTheme from './theme';

ReactDOM.createRoot(document.getElementById('root')).render(
	<StyledEngineProvider injectFirst>
		<ThemeProvider theme={AppTheme}>
			<React.Fragment>
				{/* <ScriptsLoader /> */}
				<Provider store={store}>
					<AppShell/>
					<Toast/>
				</Provider>
			</React.Fragment>
		</ThemeProvider>
	</StyledEngineProvider>,
);

