import React from "react";
import PalmettoAuthV2 from "./components/auth/PalmettoAuthV2";
import App from "./App";

const AppShell = () => {
  return (
    <PalmettoAuthV2>
      <App />
    </PalmettoAuthV2>
  );
};

export default AppShell;
