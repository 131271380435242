/**
 * Contains everything for using the Palmetto Websocket for live-updating
 */

const WEBSOCKET_URL = process.env.REACT_APP_PALMETTO_WEBSOCKET;

const POLL_TIME = 10000; // How often to poll the connection state

const RETRY_TIME = 5 * 60 * 1000; // timeout for retry

let ws = null;
let connectionActive = false;
let pollInterval = null;
let timeoutInterval = null;
let connectionStateChangeCallback = null;

let wsReadyResolve = null;
let wsReadyPromise = new Promise((res) => {
  wsReadyResolve = res;
});

let joinedRooms = {}; // Stores all joined rooms
let roomCallbacks = {}; // Stores the functions to call back when a message is received
let wsConnectValues = {}; // Stores values passed in by first connect call

export async function connect(userid, groupid, superadmin, access_token) {
  wsConnectValues = {
    userid,
    groupid,
    superadmin,
    access_token,
  };

  if (connectionActive || (ws && ws.readyState === 0)) return;
  try {
    ws = new WebSocket(
      `${WEBSOCKET_URL}?userid=${userid}&groupid=${groupid}&superadmin=${
        superadmin || 0
      }&access_token=${access_token}`,
    );
    ws.awsSend = ws.send;
    ws.send = function (data) {
      if (ws.readyState === 1) {
        ws.awsSend(data);
      }
    }.bind(ws);
  } catch (err) {
    connectionActive = false;
    console.error('Error connecting to Websocket:', err);
  }
  if (connectionStateChangeCallback) {
    connectionStateChangeCallback(true);
  }

  await new Promise((resolve) => {
    ws.onopen = () => {
      connectionActive = true;
      if (wsReadyResolve) wsReadyResolve();
      if (joinedRooms && joinedRooms.length && joinedRooms !== {}) {
        ws.send(
          JSON.stringify({
            action: 'join',
            data: {
              room: Object.keys(joinedRooms) || [],
            },
          }),
        );
      }
      resolve();
    };
  });

  ws.onmessage = (msg) => {
    if (msg) {
      let parsed = null;
      try {
        parsed = JSON.parse(msg.data);
      } catch (err) {
        console.warn('Received unparsable data!');
        return;
      }

      if (parsed.room === 'pong') {
        return;
      }

      if (parsed.room && roomCallbacks[parsed.room]) {
        roomCallbacks[parsed.room](parsed.data);
      } else {
        console.log('Room not in callbackRooms:', parsed.room);
      }
    }
  };
  pollInterval = setInterval(pollConnection, POLL_TIME);

  // close connection after 30 minutes
  timeoutInterval = setInterval(() => {
    retry();
  }, RETRY_TIME);
}

export function retry() {
  if (!connectionActive) {
    return;
  }
  ws.close();
  connectionActive = false;
  clearInterval(timeoutInterval);
  stopPolling();

  connect(
    wsConnectValues.userid,
    wsConnectValues.groupid,
    wsConnectValues.superadmin,
    wsConnectValues.access_token,
  );
}

export function disconnect() {
  if (!connectionActive) {
    return;
  }
  ws.close();
  connectionActive = false;
  stopPolling();
}

export function setConnectionStateChangeCallback(func) {
  if (func) {
    connectionStateChangeCallback = func;
  }
}

/**
 * Joins a room
 * @param {String} room Room name to join
 * @param {String} parentRoom Room that will be returned in the payload
 * @param {Function} callback Function to call when a message is received
 */
export function joinRoom(room, parentRoom, callback) {
  // Only join a room once
  if (joinedRooms[room]) return;
  // Wait for the connection to be ready and then join the room
  wsReadyPromise.then(() => {
    // Only send if we are in a ready state
    if (!ws.readyState === 1) return;
    console.log('Joining Room:', room, parentRoom);
    ws.send(
      JSON.stringify({
        action: 'join',
        data: {
          room: [room],
        },
      }),
    );
  });
  roomCallbacks[parentRoom] = callback;
  joinedRooms[room] = true;
}

function pollConnection() {
  if (ws) {
    let status = ws.readyState;
    connectionActive = status === 1;
    if (!connectionActive) {
      stopPolling();
    } else {
      ws.send(
        JSON.stringify({
          action: 'pingpong',
        }),
      );
    }
  }
}

function stopPolling() {
  clearInterval(pollInterval);
  if (connectionStateChangeCallback) {
    connectionStateChangeCallback(false);
  }
}

/**
 * Adds or updates data within a dataset by ID
 * @param {Object} newEntry
 * @param {Array} dataset
 */
export function createOrUpdateDataset(newEntry, dataset, pk = 'id') {
  if (!newEntry[pk]) return dataset;
  for (let i = 0; i < dataset.length; i++) {
    let curr = dataset[i];
    if (curr[pk] === newEntry[pk]) {
      if (newEntry.deleted === 1) {
        // Delete Entry
        dataset.splice(i, 1);
      } else {
        // Update Entry
        dataset[i] = newEntry;
      }
      return dataset;
    }
  }
  if (newEntry.deleted === 1) return; // Ignore entries that aren't added and need to be deleted
  // Add new Entry
  dataset.push(newEntry);
  return dataset;
}
